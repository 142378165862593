<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading">
      <v-card-title>
        {{
          selectedData.type && types[selectedData.type - 1]
            ? types[selectedData.type - 1].value
            : ""
        }}
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="mt-4">
          <v-col class="child-flex" cols="12" v-if="selectedData.product">
            <p>برای فایل</p>
            <v-spacer></v-spacer>
            <product-list-box
              :img="
                selectedData.product.media
                  ? selectedData.product.media.url
                  : null
              "
              :title="selectedData.product.name"
              :price="selectedData.product.price || '0'"
              :slug="selectedData.product.slug"
              :withRemove="false"
            />
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.type">
            <small>نوع</small>
            <h3 class="black--text">
              {{ types[selectedData.type - 1].value }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.percent">
            <small>درصد تخفیف</small>
            <h3 class="black--text">
              {{ selectedData.percent }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.code">
            <small>کد تخفیف</small>
            <h3 class="orange--text">
              {{ selectedData.code }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.value">
            <small>مقدار تخفیف</small>
            <h3 class="black--text">
              {{ new Intl.NumberFormat().format(selectedData.value) }} تومان
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.usage_limit">
            <small>محدودیت استفاده</small>
            <h3 class="black--text">
              {{ selectedData.usage_limit }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.usage_per_customer">
            <small>محدودیت استفاده به ازای هر کاربر</small>
            <h3 class="black--text">
              {{ selectedData.usage_per_customer }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.start_date">
            <small>تاریخ شروع</small>
            <h3 class="black--text">
              {{ selectedData.start_date | moment("jYYYY/jMM/jDD") }}
            </h3>
          </v-col>

          <v-col cols="12" sm="4" v-if="selectedData.end_date">
            <small>تاریخ پایان</small>
            <h3 class="black--text">
              {{ selectedData.end_date | moment("jYYYY/jMM/jDD") }}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";
import ProductListBox from "../Pages/ProductListBox.vue";
export default {
  components: { ProductListBox },
  data() {
    return {
      dialog: false,
      loading: false,
      types: Constants.discountTypes,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style></style>

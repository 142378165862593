<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <discounts-modal ref="detailsModal" :selectedData="selectedData" />
    <MyDataTable
      title="تخفیف های موجود"
      subtitle="مدیریت تخفیف"
      addtitle="ایجاد تخفیف جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>
          {{
            row.type && types[row.type - 1] ? types[row.type - 1].value : "-"
          }}
        </td>
        <td>{{ row.product ? row.product.name : "-" }}</td>
        <td>{{ row.usage_limit || "-" }}</td>
        <td>{{ row.percent || "-" }}</td>
        <td>{{ row.status == 1 ? "فعال" : "غیرفعال" }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import DiscountsModal from "../../../../components/Panel/DiscountsModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    DiscountsModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/discounts/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: "product",
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/discounts/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/discounts/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/discounts/index",
      pathParams: {
        with: "product",
      },
      types: Constants.discountTypes,
      headers: [
        {
          text: "نوع",
          align: "start",
          value: "type",
        },
        { text: "برای فایل", value: "product_id" },
        { text: "تعداد استفاده", value: "usage_limit" },
        { text: "درصد تخفیف", value: "percent" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
